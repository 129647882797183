import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="main-footer">
      <span>&copy; 2023. Mohiman.com. All Rights Reserved.</span>
      <span><a href="https://x.com/_mohiman" target="_blank">𝕏.com/_mohiman</a></span>
    </div>
  )
}