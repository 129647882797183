import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Form, Nav, Card, Button } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import Avatar from "../components/Avatar";

import img20 from "../assets/img/img20.jpg";
import img21 from "../assets/img/img21.jpg";
import img22 from "../assets/img/img22.jpg";
import img23 from "../assets/img/img23.jpg";
import img24 from "../assets/img/img24.jpg";
import img25 from "../assets/img/img25.jpg";
import img26 from "../assets/img/img26.jpg";
import img27 from "../assets/img/img27.jpg";
import img28 from "../assets/img/img28.jpg";
import img29 from "../assets/img/img29.jpg";
import img30 from "../assets/img/img30.jpg";
import img31 from "../assets/img/img31.jpg";

export default function Events() {
  return (
    <React.Fragment>
      <Header />
      <br></br>
      <br></br>
      <div className="main p-4 p-lg-5">
        <Row className="g-5">
          <Col xl>
            <ol className="breadcrumb fs-sm mb-2">
              <li className="breadcrumb-item"><Link to="#">About</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Certifications</li>
            </ol>
            <h2 className="main-title">Certifications</h2>

            <Nav className="nav-line mb-4">
              <Nav.Link href="" className="active">Completed</Nav.Link>
            </Nav>

            <Row className="g-3">
              {[
                {
                  "imgtop": img20,
                  "title": "Full Stack Development Certificate",
                  "date": "Issued August 2023",
                  "location": "Massachusetts Institute of Technology",
                  "link" : ""
                },{
                  "imgtop": img21,
                  "title": "Javascript Certificate",
                  "date": "Issued November 2022",
                  "location": "WeLoveNoCode",
                  "link": "https://app.welovenocode.com/certificate/?test_id=1668706275540x711037509882347500&utm_source=sharable_link&utm_medium=post&utm_campaign=posted_sm_profile"
                },{
                  "imgtop": img22,
                  "title": "UI/UX Certificate",
                  "date": "Issued November 2022",
                  "location": "WeLoveNoCode",
                  "link" : "https://app.welovenocode.com/certificate/1667062115633x403892211081711170?red=1"
                },{
                  "imgtop": img23,
                  "title": "Wordpress Development Certificate",
                  "date": "Issued November 2022",
                  "location": "WeLoveNoCode",
                  "link" : "https://app.welovenocode.com/certificate/?test_id=1668785547689x723725838104658000&utm_source=sharable_link&utm_medium=post&utm_campaign=posted_sm_profile"
                },{
                  "imgtop": img24,
                  "title": "Squarespace Development Certificate",
                  "date": "Issued November 2022",
                  "location": "WeLoveNoCode",
                  "link" : "https://app.welovenocode.com/certificate/1667062151960x828545906373292000?red=1"
                },{
                  "imgtop": img25,
                  "title": "Shopify Development Certificate",
                  "date": "Issued November 2022",
                  "location": "WeLoveNoCode",
                  "link" : "https://app.welovenocode.com/certificate/?test_id=1669393314725x921901249059356700&utm_source=sharable_link&utm_medium=post&utm_campaign=posted_sm_profile"
                },{
                  "imgtop": img26,
                  "title": "Copywriting Certificate",
                  "date": "Issued November 2022",
                  "location": "WeLoveNoCode",
                  "link" : "https://app.welovenocode.com/certificate/?test_id=1668674629680x162481812922957820&utm_source=sharable_link&utm_medium=post&utm_campaign=posted_sm_profile"
                },{
                  "imgtop": img27,
                  "title": "Codepen Certificate",
                  "date": "Issued November 2022",
                  "location": "WeLoveNoCode",
                  "link" : "https://app.welovenocode.com/certificate/?test_id=1669394743396x239686979889397760&utm_source=sharable_link&utm_medium=post&utm_campaign=posted_sm_profile"
                },{
                  "imgtop": img28,
                  "title": "Adalo Development Certificate",
                  "date": "Issued November 2022",
                  "location": "WeLoveNoCode",
                  "link" : "https://app.welovenocode.com/certificate/?test_id=1669395635797x689064057111052300&utm_source=sharable_link&utm_medium=post&utm_campaign=posted_sm_profile"
                },{
                  "imgtop": img29,
                  "title": "Notion Certificate",
                  "date": "Issued November 2022",
                  "location": "WeLoveNoCode",
                  "link" : "https://app.welovenocode.com/certificate/?test_id=1669394057766x606818060992512000&utm_source=sharable_link&utm_medium=post&utm_campaign=posted_sm_profile"
                },{
                  "imgtop": img30,
                  "title": "Google Sheets Certificate",
                  "date": "Issued November 2022",
                  "location": "WeLoveNoCode",
                  "link" : "https://app.welovenocode.com/certificate/?test_id=1668670357960x636542673000857600&utm_source=sharable_link&utm_medium=post&utm_campaign=posted_sm_profile"
                },{
                  "imgtop": img31,
                  "title": "Soft Skills & Communication Certificate",
                  "date": "Issued November 2022",
                  "location": "WeLoveNoCode",
                  "link" : "https://app.welovenocode.com/certificate/?test_id=1668674629680x162481812922957820&utm_source=sharable_link&utm_medium=post&utm_campaign=posted_sm_profile"
                }
              ].map((event, index) => (
                <Col sm="6" key={index}>
                  <Card className="card-event">
                    <a href={event.link} target="_blank" rel="noopener noreferrer"><img src={event.imgtop} className="card-img-top" alt="" /></a>
                    <Card.Body>
                      <h6><a href={event.link} target="_blank" rel="noopener noreferrer">{event.title}</a></h6>
                      <p className="mb-0">{event.date}</p>
                      <p className="text-secondary fs-sm">{event.location}</p>
                    </Card.Body>
                    <Card.Footer>
                      <Button variant="primary" onClick={() => window.open(event.link, '_blank')}><i className="ri-star-line"></i> Show Credential</Button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>

          </Col>
          <Col xl="4" className="d-none d-xl-block">
            <hr className="my-3 opacity-0" />
            <h5 className="section-title mb-4">Currently Pursuing</h5>
            <ul className="event-group">
              <li className="event-item">
                <div className="event-icon"><i className="ri-google-fill"></i></div>
                <div className="event-body">
                  <h6><Link to="">Google Project Management</Link></h6>
                  <p>Expected Completion: December 21, 2023</p>
                </div>
              </li>
            </ul>

            <hr className="my-4 opacity-0" />

            <h5 className="section-title">Overview</h5>
            <p className="text-secondary fs-sm mb-4">List of types of projects per skill</p>

            <Nav className="nav-classic">
              {[
                {
                  "icon": "ri-heart-pulse-line",
                  "name": "HTML",
                  "count": 40
                }, {
                  "icon": "ri-palette-line",
                  "name": "CSS",
                  "count": 28
                }, {
                  "icon": "ri-restaurant-line",
                  "name": "Javascript",
                  "count": 24
                }, {
                  "icon": "ri-gamepad-line",
                  "name": "JQuery",
                  "count": 12
                }, {
                  "icon": "ri-first-aid-kit-line",
                  "name": "TypeScript",
                  "count": 1
                }, {
                  "icon": "ri-home-8-line",
                  "name": "SQL",
                  "count": 11
                }, {
                  "icon": "ri-music-2-line",
                  "name": "MongoDB",
                  "count": 5
                }, {
                  "icon": "ri-robot-line",
                  "name": "React.js",
                  "count": 21
                }, {
                  "icon": "ri-shopping-bag-line",
                  "name": "Node.js",
                  "count": 16
                }, {
                  "icon": "ri-basketball-line",
                  "name": "Express.js",
                  "count": 12
                }, {
                  "icon": "ri-tv-line",
                  "name": "GraphQL",
                  "count": 4
                }
              ].map((category, index) => (
                <Nav.Link href="" key={index}><i className={category.icon}></i><span>{category.name}</span> <span className="badge">{category.count}</span></Nav.Link>
              ))}
            </Nav>

          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}