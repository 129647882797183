import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Nav, Row, Form, Button } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import Avatar from "../components/Avatar";

import img1 from "../assets/img/img1.jpg";
import img6 from "../assets/img/img6.jpg";
import img7 from "../assets/img/img7.jpg";
import img8 from "../assets/img/img8.jpg";
import img9 from "../assets/img/img9.jpg";
import img10 from "../assets/img/img10.jpg";
import img11 from "../assets/img/img11.jpg";
import img12 from "../assets/img/img12.jpg";
import img13 from "../assets/img/img13.jpg";
import img14 from "../assets/img/img14.jpg";
import img15 from "../assets/img/img15.jpg";
import img16 from "../assets/img/img16.jpg";
import img19 from "../assets/img/img19.jpg";

export default function People() {
  return (
    <React.Fragment>
      <Header />
      <br></br>
      <br></br>
      <div className="main p-4 p-lg-5">
        <Row className="g-5">
          <Col xl>
            <ol className="breadcrumb fs-sm mb-2">
              <li className="breadcrumb-item"><Link to="#">About</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Projects</li>
            </ol>
            <h2 className="main-title">Projects</h2>


            <div className="main-label-group mb-3">
              <label>Github</label>
              <Link to=""></Link>
            </div>

            <Row className="g-2 g-xxl-3 mb-5">
              {[
                {
                  "img": img6,
                  "name": "MIT Capstone Project",
                  "position": "'Bad Banking' Application",
                  "count": 5,
                  "mutual": [img1, img12],
                  "link": "https://github.com/mohimanb/BadBank-Capstone"
                }, {
                  "img": img7,
                  "name": "MIT Holy Grail App",
                  "position": "Course Project",
                  "count": 9,
                  "mutual": [img10, img11],
                  "link": "https://github.com/mohimanb/mit-holygrailapp"
                }, {
                  "img": img8,
                  "name": "MIT Restaurant Data",
                  "position": "Course Project",
                  "count": 18,
                  "mutual": [img6, img9],
                  "link": "https://github.com/mohimanb/graphQL-restaurant-data"
                }, {
                  "img": img9,
                  "name": "MIT Shopping Cart",
                  "position": "Course Project",
                  "count": 4,
                  "mutual": [img6, img7],
                  "link": "https://github.com/mohimanb/Shopping-Cart"
                }, {
                  "img": img10,
                  "name": "Basic React To Do List",
                  "position": "React Practice Application",
                  "count": 2,
                  "mutual": [img11, img1],
                  "link": "https://github.com/mohimanb/Basic-React-TodoList"
                }, {
                  "img": img11,
                  "name": "Task Tracker Web App",
                  "position": "Basic HTML, CSS, JS App",
                  "count": 13,
                  "mutual": [img1, img12],
                  "link": "https://github.com/mohimanb/TaskTracker"
                }
              ].map((connection, index) => (
                <Col sm="6" md="4" key={index}>
                  <Card className="card-people">
                    <Card.Body>
                      <Link to=""><Avatar img={connection.img} size="xl" /></Link>
                      <h6 className="mt-3"><Link to="">{connection.name}</Link></h6>
                      <p>{connection.position}</p>
                      <div className="d-grid"><Button variant="outline-primary"><i className="ri-github-fill"></i> See Github</Button></div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            <div className="main-label-group mb-3">
              <label>Bubble</label>
              <Link to=""></Link>
            </div>

            <Row className="g-2 g-xxl-3 mb-5">
              {[
                {
                  "img": img12,
                  "name": "ChipChat",
                  "position": "Chat App for Traders",
                  "count": 5,
                  "mutual": [img10, img8],
                  "link" : "https://chipchat.bubbleapps.io/version-test"
                }, {
                  "img": img6,
                  "name": "AskAway",
                  "position": "Modern Q&A App",
                  "count": 9,
                  "mutual": [img11, img7],
                  "link" : "https://askaway.bubbleapps.io/version-test"
                }, {
                  "img": img8,
                  "name": "CodeCrave",
                  "position": "Code Learning LMS",
                  "count": 18,
                  "mutual": [img7, img9],
                  "link" : "https://codecrave2.bubbleapps.io/version-test"
                }, {
                  "img": img8,
                  "name": "BuilderJobs",
                  "position": "Job Board Website",
                  "count": 18,
                  "mutual": [img7, img9],
                  "link" : "https://builderjobs.bubbleapps.io/version-test/"
                }, {
                  "img": img8,
                  "name": "Parkr.",
                  "position": "Parking Application",
                  "count": 18,
                  "mutual": [img7, img9],
                  "link" : ""
                }
              ].map((connection, index) => (
                <Col sm="6" md="4" key={index}>
                  <Card className="card-people">
                    <Card.Body>
                      <Link to=""><Avatar img={connection.img} size="xl" /></Link>
                      <h6 className="mt-3"><Link to="">{connection.name}</Link></h6>
                      <p>{connection.position}</p>
                      <div className="d-grid"><Button variant="outline-primary" onClick={() => window.open(connection.link, '_blank')}><i className="ri-window-fill"></i> See Demo</Button></div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            <div className="main-label-group mb-3">
              <label>More</label>
              <Link to=""></Link>
            </div>

            <Row className="g-2 g-xxl-3 mb-5">
              {[
                {
                  "img": img13,
                  "name": "Day & Night Events",
                  "position": "Client Website",
                  "count": 5,
                  "mutual": [img19, img8],
                  "link": "https://dayandnighteventhall.com/"
                }, {
                  "img": img14,
                  "name": "Hylan Care Pharmacy",
                  "position": "Client Website",
                  "count": 7,
                  "mutual": [img16, img13],
                  "link": "http://www.hylancarepharmacy.com/"
                }, {
                  "img": img15,
                  "name": "Eminent Electric",
                  "position": "Client Website",
                  "count": 10,
                  "mutual": [img6, img11],
                  "link": "https://eminentelectric.com/"
                }, {
                  "img": img15,
                  "name": "Jerusalem Sweets",
                  "position": "Client Website",
                  "count": 10,
                  "mutual": [img6, img11],
                  "link": "https://www.jerusalem-sweets.com/"
                }
              ].map((connection, index) => (
                <Col sm="6" md="4" key={index}>
                  <Card className="card-people">
                    <Card.Body>
                      <Link to=""><Avatar img={connection.img} size="xl" /></Link>
                      <h6 className="mt-3"><Link to="">{connection.name}</Link></h6>
                      <p>{connection.position}</p>
                      <div className="d-grid"><Button variant="outline-primary" onClick={() => window.open(connection.link, '_blank')}><i className="ri-mouse-fill"></i> See Website</Button></div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>


          </Col>
          <Col xl="4" xxl="3" className="d-none d-xl-block">
            

            <h5 className="section-title mb-4">Favorite Forks</h5>
            <ul className="people-group">
              {[
                {
                  "avatar": img6,
                  "name": "Expo",
                  "position": "An open-source platform for making universal native apps with React. Expo runs on Android, iOS, and the web.",
                  "link": "https://github.com/mohimanb/expo"
                }, {
                  "avatar": img10,
                  "name": "Stable Diffusion Web UI",
                  "position": "A browser interface based on Gradio library for Stable Diffusion.",
                  "link": "https://github.com/mohimanb/stable-diffusion-webui"
                }, {
                  "avatar": img7,
                  "name": "Python Training",
                  "position": "Python training for business analysts and traders by JPMorgan",
                  "link": "https://github.com/mohimanb/python-training"
                }, {
                  "avatar": img8,
                  "name": "The Algorithm",
                  "position": "Source code for Twitter's Recommendation Algorithm",
                  "link": "https://github.com/mohimanb/the-algorithm"
                }, {
                  "avatar": img9,
                  "name": "ViMusic",
                  "position": "Third Party Streaming App",
                  "link": "https://github.com/mohimanb/YTStreamApp"
                }, {
                  "avatar": img10,
                  "name": "SQL Data Analysis & Visualization",
                  "position": "Projects using MySQL, PostgreSQL, SQLite, Tableau, Apache Spark and pySpark.",
                  "link": "https://github.com/mohimanb/SQL-Data-Analysis-and-Visualization-Projects"
                }
              ].map((people, index) => (
                <li className="people-item" key={index}>
                  <Avatar img={people.avatar} />
                  <div className="people-body">
                    <h6><Link to="" onClick={() => window.open(people.link, '_blank')}>{people.name}</Link></h6>
                    <span>{people.position}</span>
                  </div>
                </li>
              ))}
            </ul>

            
            <hr className="my-4 opacity-0" />

            <h5 className="section-title">Projects Stats</h5>
            <p className="text-secondary fs-sm mb-4">Types of Projects Totals</p>

            <Nav className="nav-classic">
              {[
                {
                  "name": "HTML",
                  "count": 27
                }, {
                  "name": "CSS",
                  "count": 27
                }, {
                  "name": "Javascript",
                  "count": 22
                }, {
                  "name": "React.js",
                  "count": 5
                }, {
                  "name": "MongoDB",
                  "count": 3
                }, {
                  "name": "Express.js",
                  "count": 4
                }, {
                  "name": "Node.js",
                  "count": 4
                }, {
                  "name": "GraphQL",
                  "count": 2
                }, {
                  "name": "Bubble.io",
                  "count": 4
                }
              ].map((position, index) => (
                <Nav.Link to="" key={index}><span>{position.name}</span> <span className="badge">{position.count}</span></Nav.Link>
              ))}
            </Nav>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}