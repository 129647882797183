import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Nav, Row } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import Avatar from "../components/Avatar";

import mo from "../assets/img/mo.png";
import img5 from "../assets/img/img5.jpg";
import img6 from "../assets/img/img6.jpg";
import img7 from "../assets/img/img7.jpg";
import img8 from "../assets/img/img8.jpg";
import img9 from "../assets/img/img9.jpg";
import img10 from "../assets/img/img10.jpg";
import img11 from "../assets/img/img11.jpg";
import img12 from "../assets/img/img12.jpg";
import img20 from "../assets/img/img20.jpg";
import img21 from "../assets/img/img21.jpg";
import img22 from "../assets/img/img22.jpg";
import img23 from "../assets/img/img23.jpg";
import img24 from "../assets/img/img24.jpg";

export default function Profile() {
  return (
    <React.Fragment>
      <Header />
      <br></br>
      <br></br>
      <div className="main p-4 p-lg-5">
        <Row className="g-5">
          <Col xl>
            <div className="media-profile mb-5">
              <div className="media-img mb-3 mb-sm-0">
                <img src={mo} className="img-fluid" alt="..." />
              </div>
              <div className="media-body">
                <h5 className="media-name">Mohiman B.</h5>
                <p className="d-flex gap-2 mb-4"><i className="ri-map-pin-line"></i> New York City, New York</p>
                <p className="mb-0">
                Dynamic Junior Full Stack Developer skilled in HTML5, CSS3, JavaScript, and MERN stack technologies.
Strong track record in designing, building, and maintaining responsive websites and applications. Expertise in
applying business knowledge with a technical background to provide efficient tech solutions. 
                </p>
              </div>
            </div>

            <Row className="row-cols-sm-auto g-4 g-md-5 g-xl-4 g-xxl-5">
              {[
                {
                  "icon": "ri-medal-2-line",
                  "text": "12 Certificates",
                  "label": "Achievements"
                }, {
                  "icon": "ri-suitcase-line",
                  "text": "5+ Years",
                  "label": "Experience"
                }, {
                  "icon": "ri-pages-line",
                  "text": "6",
                  "label": "Libraries"
                }, {
                  "icon": "ri-picture-in-picture-line",
                  "text": "15",
                  "label": "Skills"
                }
              ].map((profileItem, index) => (
                <Col key={index}>
                  <div className="profile-item">
                    <i className={profileItem.icon}></i>
                    <div className="profile-item-body">
                      <p>{profileItem.text}</p>
                      <span>{profileItem.label}</span>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>

            <Nav className="nav-line mt-5">
              <Nav.Link href="" className="active">About Me</Nav.Link>
            </Nav>


            <Card className="card-post mt-4">
              <Card.Header>
                <Card.Title>Full Stack Developer</Card.Title>
              </Card.Header>
              <Card.Body>
                <p className="post-text">
                My passion for design and creativity, combined with my technical abilities, has enabled me to develop captivating webapps 
                that not only look great but also provide a user-friendly experience. I comprehend the relationship between design and 
                technology and how they come together to create a webapp that is both aesthetically pleasing and functional.
                  </p>
              </Card.Body>
            </Card>

            <Card className="card-post mt-4">
              <Card.Header>
                <Card.Title>Work Experience</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="experience-item">
                  <div className="experience-icon"><i className="ri-suitcase-line"></i></div>
                  <div className="experience-body">
                    <h5>Front-End Developer</h5>
                    <p>Amanah Studios</p>
                    <p>February 2021 - Present</p>
                    <ul className="mt-3">
                      <li>Led the design and development of 30+ responsive websites using HTML, CSS, and JavaScript.</li>
                      <li>Enhanced website speed and performance by 35% through diligent performance audits and subsequent optimizations.</li>
                      <li>Constructed and maintained custom WordPress themes and plugins to enhance site functionality and improve UX.</li>
                      <li>Coordinated closely with clients, garnering their requirements, and providing consistent updates, thereby improving
                          client relationships.</li>
                    </ul>
                  </div>
                </div>
              </Card.Body>
              <Card.Body>
                <div className="experience-item">
                  <div className="experience-icon"><i className="ri-suitcase-line"></i></div>
                  <div className="experience-body">
                    <h5>Freelance Developer</h5>
                    <p>WeLoveNoCode</p>
                    <p>October 2020 - Present</p>
                    <ul className="mt-3">
                      <li>Pioneered the development and launch of various interactive web applications using Bubble.io, improving overall client
                          satisfaction by 30%. </li>
                      <li>Crafted custom themes and plugins for WordPress, Squarespace, and Shopify platforms, contributing to the enhancement
                          of website functionalities.</li>
                      <li>Demonstrated a strong ability to work with clients remotely, ensuring clarity of project requirements and maintaining a
                          consistent communication flow for updates.</li>
                    </ul>
                  </div>
                </div>
              </Card.Body>
              <Card.Body>
                <div className="experience-item">
                  <div className="experience-icon"><i className="ri-suitcase-line"></i></div>
                  <div className="experience-body">
                    <h5>Business Analyst</h5>
                    <p>Facility Solutions Group</p>
                    <p>October 2018 - February 2021</p>
                    <ul className="mt-3">
                      <li>Orchestrated initiatives for data cleansing and root cause analysis, leading to a 20% improvement in data accuracy.</li>
                      <li>Pioneered the creation of comprehensive data models, reports, and charts, effectively facilitating data-driven decisions.</li>
                      <li>Utilized SQL queries and ETL tools to support a wide variety of data sources, improving data management efficiency.</li>
                      <li>Worked with Tableau, SSIS, SQL, SSAS, MS Excel, and Python to support data analysis, leading to more informed and
                          effective business insights.</li>
                    </ul>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer>
                  Updated: August 4th, 2023
              </Card.Footer>
            </Card>

            <Card className="card-post mt-4">
              <Card.Header>
                <Card.Title>Education</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="experience-item">
                  <div className="experience-icon"><i className="ri-pencil-line"></i></div>
                  <div className="experience-body">
                    <h5>Massachusetts Institute of Technology</h5>
                    <p>Certificate in Full Stack Development</p>
                    <p>April 2022 - August 2023</p>
                    <ul className="mt-3">
                      <li>Gained hands-on experience in building, testing, and deploying web applications using MERN stack.</li>
                      <li>Established CI/CD pipelines for seamless React application deployment via AWS.</li>
                      <li>Developed proficiency in working on and delivering several front-end applications using React.js.</li>
                    </ul>
                  </div>
                </div>
              </Card.Body>
              <Card.Body>
                <div className="experience-item">
                  <div className="experience-icon"><i className="ri-pencil-line"></i></div>
                  <div className="experience-body">
                    <h5>Baruch College — Zicklin School of Business</h5>
                    <p>Bachelor in Business Administration; Finance Major, Communications Minor</p>
                    <p>Completed May 2018</p>
                    <ul className="mt-3">
                      <li>Distinguished academic record with GPA of 3.7/4.0</li>
                      <li>Undertook courses such as Econometrics, Advanced Statistics, Advanced Corporate Finance, Investment Analysis, Financial
                          Management, Operations Management, Futures & Derivatives, and Minors in Business Communications.</li>
                    </ul>
                  </div>
                </div>
              </Card.Body>
            </Card>

            <Card className="card-post mt-4">
              <Card.Header>
                <Card.Title>Areas of Expertise</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="interest-item">
                  <div className="interest-icon bg-twitter"><i className="ri-drag-drop-fill"></i></div>
                  <div className="interest-body">
                    <h6>User Interface & Experience</h6>
                    <p>Building memorable user interfaces and designs to leave lasting impressions and valuable expereinces.</p>
                  </div>
                </div>
                <div className="interest-item">
                  <div className="interest-icon bg-twitter"><i className="ri-stack-fill"></i></div>
                  <div className="interest-body">
                    <h6>Full Stack Development</h6>
                    <p>Development of Web Applications using the MERN Stack: React.js, Express.js, MongoDB, and Node.js</p>
                  </div>
                </div>
                <div className="interest-item">
                  <div className="interest-icon bg-twitter"><i className="ri-sword-line"></i></div>
                  <div className="interest-body">
                    <h6>CMS Systems</h6>
                    <p>In depth work in platforms such as WordPress, Shopify, Squarespace. As well as headless CMSs such as Strapi.</p>
                  </div>
                </div>
                <div className="interest-item">
                  <div className="interest-icon bg-twitter"><i className="ri-code-s-slash-line"></i></div>
                  <div className="interest-body">
                    <h6>No Code / Low Code Development</h6>
                    <p>Development via advanced platforms such as Bubble and Adalo. Further experience in Appian.</p>
                  </div>
                </div>
                <div className="interest-item">
                  <div className="interest-icon bg-twitter"><i className="ri-align-right"></i></div>
                  <div className="interest-body">
                    <h6>Prompt Engineering</h6>
                    <p>Self-taught in developing with AI tools such as GPT-3 and Midjourney. Building advanced tools and imagery. <Link to="">See Prompt Library</Link></p>
                  </div>
                </div>
                <div className="interest-item">
                  <div className="interest-icon bg-twitter"><i className="ri-aliens-fill"></i></div>
                  <div className="interest-body">
                    <h6>Logo Design</h6>
                    <p>Ideating iconic and unforgettable logo designs, industry fitting, timeless, multi-format-ready, print-ready, applicable to any medium. <a href="">See Dribbble</a></p>
                  </div>
                </div>
                <div className="interest-item">
                  <div className="interest-icon bg-twitter"><i className="ri-anchor-fill"></i></div>
                  <div className="interest-body">
                    <h6>Brand Strategy</h6>
                    <p>Designing plans of action for market penetration, increasing adoption, industry compliance, iteration and deployment.</p>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer>
                  <p>Updated: August 4th, 2023</p>
              </Card.Footer>
            </Card>
          </Col>
          <Col xl="4" xxl="3" className="d-none d-xl-block">
            <h5 className="main-title mb-0">Certificates</h5>
            <br></br>
            <div className="profile-mutual">
              <ul className="mutual-group mb-3">
                <li><Avatar img={img24} /></li>
                <li><Avatar img={img23} /></li>
                <li><Avatar img={img22} /></li>
                <li><Avatar img={img21} /></li>
                <li><Avatar img={img20} /></li>
              </ul>
              <Link to="/pages/events" ><h6>12 Certifications</h6></Link>
              <p>Software Development, Business, Management, Marketing, Writing, and more.</p>
            </div>

            <hr className="my-4 opacity-0" />
            <h4 className="main-title mb-0">Skills</h4>
            <br></br>
            <h5 className="section-title mb-4">Programming Languages</h5>
            <ul className="people-group">
              {[
                {
                  "avatar": img6,
                  "name": "HTML5",
                }, {
                  "avatar": img7,
                  "name": "CSS3",
                }, {
                  "avatar": img8,
                  "name": "Javascript ES6",
                }, {
                  "avatar": img9,
                  "name": "SQL",
                }
              ].map((people, index) => (
                <li className="people-item" key={index}>
                  <Avatar img={people.avatar} />
                  <div className="people-body">
                    <h6><Link to="">{people.name}</Link></h6>
                    <span>{people.position}</span>
                  </div>
                </li>
              ))}
            </ul>

            <hr className="my-4 opacity-0" />
            <h5 className="section-title mb-4">Databases</h5>
            <ul className="people-group">
              {[
                {
                  "avatar": img6,
                  "name": "MongoDB",
                }
              ].map((people, index) => (
                <li className="people-item" key={index}>
                  <Avatar img={people.avatar} />
                  <div className="people-body">
                    <h6><Link to="">{people.name}</Link></h6>
                    <span>{people.position}</span>
                  </div>
                </li>
              ))}
            </ul>

            <hr className="my-4 opacity-0" />
            <h5 className="section-title mb-4">Libraries & Frameworks</h5>
            <ul className="people-group">
              {[
                {
                  "avatar": img11,
                  "name": "React.js",
                }, {
                  "avatar": img12,
                  "name": "Express.js"
                }, {
                  "avatar": img6,
                  "name": "Redux"
                }, {
                  "avatar": img7,
                  "name": "Node.js"
                }, {
                  "avatar": img8,
                  "name": "JQuery"
                }, {
                  "avatar": img8,
                  "name": "GraphQL"
                }
              ].map((people, index) => (
                <li className="people-item" key={index}>
                  <Avatar img={people.avatar} />
                  <div className="people-body">
                    <h6><Link to="">{people.name}</Link></h6>
                    <span>{people.position}</span>
                  </div>
                </li>
              ))}
            </ul>

            <hr className="my-4 opacity-0" />
            <h5 className="section-title mb-4">Tools & Software</h5>
            <ul className="people-group">
              {[
                {
                  "avatar": img11,
                  "name": "VS Code",
                }, {
                  "avatar": img12,
                  "name": "Figma"
                }, {
                  "avatar": img6,
                  "name": "GitHub"
                }, {
                  "avatar": img7,
                  "name": "Postman"
                }, {
                  "avatar": img8,
                  "name": "Tableau"
                }, {
                  "avatar": img8,
                  "name": "Microsoft Office Suite"
                }, {
                  "avatar": img8,
                  "name": "Adobe Creative Suite"
                }
              ].map((people, index) => (
                <li className="people-item" key={index}>
                  <Avatar img={people.avatar} />
                  <div className="people-body">
                    <h6><Link to="">{people.name}</Link></h6>
                    <span>{people.position}</span>
                  </div>
                </li>
              ))}
            </ul>

            <hr className="my-4 opacity-0" />
            <h5 className="section-title mb-4">Web Platforms</h5>
            <ul className="people-group">
              {[
                {
                  "avatar": img6,
                  "name": "Bubble.io",
                }, {
                  "avatar": img7,
                  "name": "WordPress",
                }, {
                  "avatar": img8,
                  "name": "Squarespace",
                }, {
                  "avatar": img9,
                  "name": "Shopify",
                }
              ].map((people, index) => (
                <li className="people-item" key={index}>
                  <Avatar img={people.avatar} />
                  <div className="people-body">
                    <h6><Link to="">{people.name}</Link></h6>
                    <span>{people.position}</span>
                  </div>
                </li>
              ))}
            </ul>

            <hr className="my-4 opacity-0" />
            <h5 className="section-title mb-4">Other Skills</h5>
            <ul className="people-group">
              {[
                {
                  "avatar": img6,
                  "name": "Web Applications",
                }, {
                  "avatar": img7,
                  "name": "Agile Methodologies",
                }, {
                  "avatar": img8,
                  "name": "Responsive Web Design",
                }, {
                  "avatar": img9,
                  "name": "Professional Writing",
                }, {
                  "avatar": img9,
                  "name": "Public Speaking",
                }
              ].map((people, index) => (
                <li className="people-item" key={index}>
                  <Avatar img={people.avatar} />
                  <div className="people-body">
                    <h6><Link to="">{people.name}</Link></h6>
                    <span>{people.position}</span>
                  </div>
                </li>
              ))}
            </ul>

            <hr className="my-4 opacity-0" />
            <h5 className="section-title mb-4">Contact Information</h5>
            <ul className="list-contact-info">
              <li><i className="ri-building-fill"></i><span>New York City, NY</span></li>
              <li><i className="ri-phone-fill"></i><span>Available Upon Request</span></li>
              <li><i className="ri-mail-fill"></i><span>hello@mohiman.com</span></li>
            </ul>

            <hr className="my-4 opacity-0" />

            <h5 className="section-title mb-4">Socials</h5>
            <ul className="list-contact-info">
              <li><i className="ri-twitter-fill"></i><span><a href="https://x.com/_mohiman">@_mohiman</a></span></li>
              <li><i className="ri-linkedin-fill"></i><span><a href="https://www.linkedin.com/in/mohiman/">@mohiman</a></span></li>
              <li><i className="ri-codepen-fill"></i><span><a href="https://codepen.io/mohiman">@mohiman</a></span></li>
              <li><i className="ri-dribbble-fill"></i><span><a href="https://dribbble.com/Mohiman">@mohiman</a></span></li>
            </ul>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}