import React, { useEffect } from "react";
import Prism from "prismjs";
import { Card, Container, Nav } from "react-bootstrap";
import Footer from "../../layouts/Footer";
import HeaderMobile from "../../layouts/HeaderMobile";

export default function CSSIntroduction() {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main main-docs">
        <Container>
          <label className="main-title-label">CSS Documentation</label>
          <h2 className="main-title">Introduction to CSS</h2>
          <p className="main-title-text">
            Cascading Style Sheets, commonly known as CSS, is a design language intended to simplify the process of making web documents presentable. Its primary function is to style web pages and interfaces written in HTML and XHTML, but it can also be applied to any XML document. This documentation will provide a comprehensive guide to understanding and effectively utilizing CSS in web development.
          </p>

          <hr className="main-separator" />

          <h5 id="section1" className="main-subtitle">Overview of CSS Documentation</h5>
          <p>Throughout this documentation, we will explore various facets of CSS, including:</p>
          <ul>
            <li><strong>Selectors:</strong> Understand the methods to select elements for styling.</li>
            <li><strong>Properties:</strong> Dive deep into the plethora of style properties CSS offers.</li>
            <li><strong>Layouts:</strong> Discover techniques for building responsive and organized layouts.</li>
            <li><strong>Animations & Transitions:</strong> Add dynamic visuals to your web pages.</li>
            <li><strong>Best Practices:</strong> Learn the recommended practices to ensure efficient and maintainable CSS code.</li>
          </ul>

          <hr className="main-separator" />

          <h5 id="section2" className="main-subtitle">The Power of CSS</h5>
          <p>
            CSS brings life to the web. With the capability to alter layouts, colors, fonts, and more, CSS is the toolkit every web developer needs. Whether you're aiming for a minimalist design or a vibrant and dynamic appearance, CSS can achieve it. In our subsequent sections, we'll delve into the intricacies of CSS properties and selectors, enabling you to harness the full power of this design language.
          </p>
          <Card className="card-example">
            <Card.Body>
              <div className="demo-css">
                Example of how CSS changes the look and feel of a simple HTML element.
              </div>
            </Card.Body>
            <Card.Footer>
              <pre><code className="language-html">{`<div className="demo-css">Styled with CSS</div>`}</code></pre>
            </Card.Footer>
          </Card>

          <hr className="main-separator" />

          <h5 id="section3" className="main-subtitle">The Evolution of CSS</h5>
          <p>
            From its inception in the late 1990s to the present day, CSS has undergone numerous revisions and updates. With each version, new features, optimizations, and improvements are introduced, ensuring that CSS remains at the forefront of web design. This documentation aims to provide up-to-date information on the latest features while also giving a nod to the foundational concepts that remain integral to CSS.
          </p>

          <br /><br /><br />
        </Container>

        <Nav id="navDocs" className="nav-docs">
          <label>On This Page</label>
          <Nav.Link href="#section1">Overview of CSS Documentation</Nav.Link>
          <Nav.Link href="#section2">The Power of CSS</Nav.Link>
          <Nav.Link href="#section3">The Evolution of CSS</Nav.Link>
        </Nav>
      </div>
      <Footer />
    </React.Fragment>
  );
}
