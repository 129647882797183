import React, { useEffect } from "react";
import Prism from "prismjs";
import { Accordion, Card, Container, Nav } from "react-bootstrap";
import Footer from "../../layouts/Footer";
import HeaderMobile from "../../layouts/HeaderMobile";

export default function Accordions() {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main main-docs">
        <Container>
          <label className="main-title-label">Web Development</label>
          <h2 className="main-title">The role of CSS in web development</h2>
          <p className="main-title-text">CSS is the backbone of web design, allowing developers to style web pages and create visually appealing sites.</p>

          <hr className="main-separator" />

          <h5 id="section1" className="main-subtitle">Introduction to CSS</h5>
          <p>CSS, or Cascading Style Sheets, is a language used to describe the look and formatting of a document written in HTML or XML. Through CSS, developers can control the layout, colors, fonts, and more.</p>

          <Card className="card-example">
            <Card.Body>
              <p className="mb-2"><strong>For instance, consider an HTML element:</strong></p>
              <pre><code className="language-html">{`<div class="highlighted-text">Hello World!</div>`}</code></pre>
              <p className="mb-0">Using CSS, we can style this element as follows:</p>
              <pre><code className="language-css">{`.highlighted-text {
  color: blue;
  font-weight: bold;
}`}</code></pre>
              <p>The above CSS code will make the text blue and bold.</p>
            </Card.Body>
          </Card>

          <br /><br /><br />

          <h5 id="section2" className="main-subtitle">Advantages of Using CSS</h5>
          <p>CSS offers numerous advantages in web development, making it an essential tool for developers.</p>

          <Card className="card-example">
            <Card.Body>
              <p className="mb-2"><strong>Some of the key benefits include:</strong></p>
              <ul>
                <li>Separation of Content and Design: CSS allows for a clear distinction between the structure (HTML) and presentation (CSS).</li>
                <li>Site-wide consistency: By using a single CSS file, changes can be applied site-wide, ensuring uniformity.</li>
                <li>Flexibility: CSS can be used with various markup languages, not just HTML.</li>
                <li>Improved load times: With CSS, styles are loaded once and cached, resulting in faster page loads.</li>
              </ul>
            </Card.Body>
          </Card>

          <br /><br /><br />

          <h5 id="section3" className="main-subtitle">CSS Frameworks</h5>
          <p>CSS frameworks, like Bootstrap, Materialize, or Tailwind, provide pre-written CSS rules to help developers build responsive and aesthetically pleasing web applications rapidly.</p>

          <Card className="card-example">
            <Card.Body>
              <p className="mb-2"><strong>For instance, using Bootstrap:</strong></p>
              <pre><code className="language-html">{`<button class="btn btn-primary">Click Me</button>`}</code></pre>
              <p className="mb-0">The above code will produce a blue button without having to manually write the CSS for it. This shows the power and efficiency of CSS frameworks in web development.</p>
            </Card.Body>
          </Card>

          <br /><br /><br />

          {/* Add more sections as needed... */}

          <Footer />
        </Container>
      </div>
    </React.Fragment>
  );
}
