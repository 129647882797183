import React from "react";
import { Link } from "react-router-dom";

import mo from "../assets/img/mo.png";

// eslint-disable-next-line
export default Notification = [
  {
    "avatar": (<img src={mo} alt=""/>),
    "text": (<React.Fragment><strong>Mohiman B.</strong> is on <Link to="https://www.linkedin.com/in/mohiman/" ><strong>LinkedIn</strong></Link>! Come and give a follow.</React.Fragment>),
  }
];

