import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";

import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";

export default function App() {
  // Netlify Identity initialization
  useEffect(() => {
    const netlifyIdentity = window.netlifyIdentity;

    // Initialize netlify identity connection
    netlifyIdentity.init();
    
    // Optionally, set up event listeners if you want to perform actions on login/logout
    // netlifyIdentity.on('login', user => {
    //   console.log("User has logged in:", user);
    // });
    // netlifyIdentity.on('logout', () => {
    //   console.log("User has logged out");
    // });

    // Cleanup listeners upon component unmount
    return () => {
      // netlifyIdentity.off('login');
      // netlifyIdentity.off('logout');
    };
  }, []);

  // Set skin on load outside React component
  window.addEventListener("load", function () {
    let skinMode = localStorage.getItem("skin-mode");
    let HTMLTag = document.querySelector("html");

    if (skinMode) {
      HTMLTag.setAttribute("data-skin", skinMode);
    }
  });

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            {protectedRoutes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  element={route.element}
                  key={index}
                />
              )
            })}
          </Route>
          {publicRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={route.element}
                key={index}
              />
            )
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}
